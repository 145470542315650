<template>
  <div class="col-lg col-12 mb-2 mb-lg-0">
    <div class="row m-0">
      <div class="col-12 p-0 mb-1">
        <SelectProjectSlider
          v-model="selectedProjectValue"
          id="project"
          :options="visiblePosters"
          placeholder="Projects"
          color="white"
          :currency="currency"
        />
      </div>
      <div class="col-12 p-0">
        <SelectAll
          v-if="Object.keys(selectedProjectValue).length !== 0 && projectWithTypes"
          v-model="donationTypeValue"
          id="donationTypeValue"
          :options="projectWithTypes.projectDonationTypeMaps"
          placeholder="Donation Type"
          displayValue="donationType"
          subDisplayValue="description"
          color="white"
          :autoSelect="true"
          :disabled="Object.keys(selectedProjectValue).length === 0 || Object.keys(projectWithTypes).length === 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    SelectProjectSlider: defineAsyncComponent(() => import('../components/SelectProjectSlider.vue'))
  },
  props: ['selectedProject', 'donationType', 'projectWithTypes', 'visiblePosters', 'currency'],
  emits: ['update:selectedProject', 'update:donationType'],
  name: 'Select Project Options',
  computed: {
    selectedProjectValue: {
      get () {
        return this.selectedProject
      },
      async set (val) {
        await this.$emit('update:selectedProject', val)
      }
    },
    donationTypeValue: {
      get () {
        return this.donationType
      },
      set (val) {
        this.$emit('update:donationType', val)
      }
    }
  }
}
</script>
